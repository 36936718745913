<template>
  <en-drawer :title="title" :model-value="modelValue" @close="$emit('update:model-value', false)" :size="size">
    <en-form v-if="type === 'A'" :model="form.data">
      <en-form-item label="工时费">{{ formatMoney(form.maintenanceFee.amount) }}</en-form-item>
      <en-form-item label="折扣">
        <en-input-rate
          v-model="form.data.maintenanceDiscountRate"
          :precision="6"
          :disabled="!form.maintenanceFee.amount"
          class="w-full"
          @change="form.maintenanceDiscountRate.change"
        ></en-input-rate>
      </en-form-item>
      <en-form-item label="优惠金额">
        <en-input-number
          v-model="form.data.maintenanceAmount"
          :precision="2"
          :disabled="!form.maintenanceFee.amount"
          class="w-full"
          @change="form.maintenanceAmount.change"
        ></en-input-number>
      </en-form-item>
      <en-form-item label="折后工时费">{{ formatMoney(form.maintenanceFee.amountAfterdiscount) }}</en-form-item>
      <en-form-item label="配件费">{{ formatMoney(form.goodsFee.amount) }}</en-form-item>
      <en-form-item label="折扣">
        <en-input-rate
          v-model="form.data.goodsDiscountRate"
          :precision="6"
          :disabled="!form.goodsFee.amount"
          class="w-full"
          @change="form.goodsDiscountRate.change"
        ></en-input-rate>
      </en-form-item>
      <en-form-item label="优惠金额">
        <en-input-number
          v-model="form.data.goodsAmount"
          :precision="2"
          :disabled="!form.goodsFee.amount"
          class="w-full"
          @change="form.goodsAmount.change"
        ></en-input-number>
      </en-form-item>
      <en-form-item label="折后配件费">{{ formatMoney(form.goodsFee.amountAfterdiscount) }}</en-form-item>
    </en-form>

    <en-collapse v-if="type === 'S'">
      <en-collapse-item title="维修项目">
        <en-table :data="form.data.maintenances">
          <en-table-column label="项目编号" prop="maintenance.serialNo"></en-table-column>
          <en-table-column label="维修项目" prop="name"></en-table-column>
          <en-table-column label="工时" prop="laborHour"></en-table-column>
          <en-table-column label="内部工时" prop="internalLaborHour"></en-table-column>
          <en-table-column label="单价">
            <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceMaintenanceDto'] }">{{ formatMoney(row.price) }} </template>
          </en-table-column>
          <en-table-column label="金额">
            <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceMaintenanceDto'] }">
              {{ formatMoney(row.price * row.laborHour) }}
            </template>
          </en-table-column>
          <en-table-column label="折扣" prop="discountRate" width="150">
            <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceMaintenanceDto'] }">
              <en-input-rate v-model="row.discountRate" class="w-full"></en-input-rate>
            </template>
          </en-table-column>
          <en-table-column label="优惠金额">
            <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceMaintenanceDto'] }">
              {{ formatMoney(row.price * row.laborHour - row.price * row.laborHour * row.discountRate) }}
            </template>
          </en-table-column>
          <en-table-column label="折后金额">
            <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceMaintenanceDto'] }"
              >{{ formatMoney(row.price * row.laborHour * row.discountRate) }}
            </template>
          </en-table-column>
          <en-table-column label="收费类别" prop="chargingMethod.message"></en-table-column>
          <en-table-column label="派工班组" prop="workingTeam.name"></en-table-column>
          <en-table-column label="派工技师">
            <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceMaintenanceDto'] }">
              {{ row.assignees?.map((item) => (item.assignee ? item.assignee.name : item.name)).join(',') }}
            </template>
          </en-table-column>
        </en-table>
      </en-collapse-item>
      <en-collapse-item title="维修配件">
        <en-table :data="form.data.goods">
          <en-table-column label="配件编号" prop="goodsSpecification.goods.serialNo"></en-table-column>
          <en-table-column label="配件名称" prop="goodsSpecification.goods.name"></en-table-column>
          <en-table-column label="实际用量" prop="count"></en-table-column>
          <en-table-column label="单价" prop="price">
            <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceMaintenanceDto'] }">
              {{ formatMoney(row.price) }}
            </template>
          </en-table-column>
          <en-table-column label="金额" prop="origin">
            <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceMaintenanceDto'] }">
              {{ formatMoney(row.count * row.price) }}
            </template>
          </en-table-column>
          <en-table-column label="折扣" prop="discountRate" width="150">
            <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceMaintenanceDto'] }">
              <en-input-rate v-model="row.discountRate" class="w-full"></en-input-rate>
            </template>
          </en-table-column>
          <en-table-column label="优惠金额">
            <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceMaintenanceDto'] }">
              {{ formatMoney(row.count * row.price - row.price * row.count * row.discountRate) }}
            </template>
          </en-table-column>
          <en-table-column label="折后金额">
            <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceMaintenanceDto'] }">
              {{ formatMoney(row.price * row.count * row.discountRate) }}
            </template>
          </en-table-column>
          <en-table-column label="收费类别" prop="chargingMethod.message"></en-table-column>
        </en-table>
      </en-collapse-item>
    </en-collapse>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <en-button type="primary" @click="footer.confirm.click">确定</en-button>
    </template>
  </en-drawer>
</template>

<script lang="ts">
import { cloneDeep } from 'lodash-es'
import { calculator } from '@enocloud/utils'

interface Fee {
  maintenance: number
  goods: number
}

export interface DiscountRes {
  maintenances: EnocloudServiceDefinitions['ServiceMaintenanceDto'][]
  goods: EnocloudServiceDefinitions['ServiceGoodsDto'][]
}

export default factory({
  props: {
    modelValue: Boolean,
    fee: Object as PropType<Fee>,
    type: String,
    maintenances: Array as PropType<EnocloudServiceDefinitions['ServiceMaintenanceDto'][]>,
    goods: Array as PropType<EnocloudServiceDefinitions['ServiceGoodsDto'][]>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: (value: DiscountRes) => typeof value === 'object'
  },

  computed: {
    title() {
      return this.type === 'S' ? '单项折扣' : '整单折扣'
    },
    size() {
      return this.type === 'S' ? 1200 : 378
    }
  },

  watch: {
    modelValue: {
      handler() {
        if (this.modelValue) {
          this.form.data.maintenances = cloneDeep(this.maintenances) ?? []
          this.form.data.goods = cloneDeep(this.goods) ?? []
        }
      }
    }
  },

  config: {
    children: {
      form: {
        data: {
          maintenanceDiscountRate: 1,
          maintenanceAmount: 0,
          goodsDiscountRate: 1,
          goodsAmount: 1,
          maintenances: [] as EnocloudServiceDefinitions['ServiceMaintenanceDto'][],
          goods: [] as EnocloudServiceDefinitions['ServiceGoodsDto'][]
        },
        computed: {
          maintenanceFee() {
            return this.form.data.maintenances.reduce(
              (fee, item) => {
                const amount = calculator.mul(item.laborHour, item.price)
                fee.amount = calculator.add(fee.amount, amount)
                fee.amountAfterdiscount = calculator.add(fee.amountAfterdiscount, calculator.mul(amount, item.discountRate))
                return fee
              },
              { amount: 0, amountAfterdiscount: 0 }
            )
          },
          goodsFee() {
            return this.form.data.goods.reduce(
              (fee, item) => {
                const amount = calculator.mul(item.count, item.price)
                fee.amount = calculator.add(fee.amount, amount)
                fee.amountAfterdiscount = calculator.add(fee.amountAfterdiscount, calculator.mul(amount, item.discountRate))
                return fee
              },
              { amount: 0, amountAfterdiscount: 0 }
            )
          }
        },
        children: {
          maintenanceDiscountRate: {
            change() {
              for (const item of this.form.data.maintenances) {
                item.discountRate = this.form.data.maintenanceDiscountRate
              }

              this.form.data.maintenanceAmount = calculator.sub(this.form.maintenanceFee.amount, this.form.maintenanceFee.amountAfterdiscount)
            }
          },
          maintenanceAmount: {
            change() {
              const discountRate = calculator.sub(1, calculator.div(this.form.data.maintenanceAmount, this.form.maintenanceFee.amount))
              this.form.data.maintenanceDiscountRate = discountRate
              for (const item of this.form.data.maintenances) {
                item.discountRate = discountRate
              }
            }
          },
          goodsDiscountRate: {
            change() {
              for (const item of this.form.data.goods) {
                item.discountRate = this.form.data.goodsDiscountRate
              }
            }
          },
          goodsAmount: {
            change() {
              const discountRate = calculator.sub(1, calculator.div(this.form.data.goodsAmount, this.form.goodsFee.amount))
              this.form.data.goodsDiscountRate = discountRate
              for (const item of this.form.data.goods) {
                item.discountRate = discountRate
              }
            }
          }
        }
      },
      footer: {
        children: {
          cancel: {
            click() {
              this.emit('update:model-value', false)
            }
          },
          confirm: {
            click() {
              this.emit('confirm', this.form.data)
              this.footer.cancel.click()
            }
          }
        }
      }
    }
  }
})
</script>
